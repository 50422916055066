<template>
  <div class="home">
    <div class="top">
      <h1>Welcome</h1>
      <p>When life presents challenges, Shimna Therapy can help you with the choices and changes that you need to make</p>
    </div>
    <div class="middle">
      <div class="mid-left">
        <div class="container">

        
          <div class="sub-titles">
            <h1>Our Approach To Therapy</h1>
            <h3>Safe. Effective. Efficient.</h3>
          </div>
          <div class="bullet-points">
            <div class="bp-group">
              <div class="bp-icon">
                <Icon icon="mdi:tick-circle" color="floralwhite" width="30" height="30" />
              </div>
              <div class="bp-text">
                At Shimna Therapy we offer person-centred responses that address a wide range of issues safely and efficiently.
              </div>
            </div>
            <div class="bp-group">
              <div class="bp-icon">
                <Icon icon="mdi:tick-circle" color="floralwhite" width="30" height="30" />
              </div>
              <div class="bp-text">
                Through our exclusive person-centred, responsive and effective therapy our clients grow in confidence, self-reliance and resilience.
              </div>
            </div>
            <div class="bp-group">
              <div class="bp-icon">
                <Icon icon="mdi:tick-circle" color="floralwhite" width="30" height="30" />
              </div>
              <div class="bp-text">
                In creative, therapeutic relationship  helps clients perceive biases, denials and come to live a more genuine, authentic and satisfying life.
              </div>
            </div>
          </div>
          <button class="btnSchedule" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/shimnatherapy?background_color=FFFAF0&text_color=333&primary_color=56b7ad'});return false;">Schedule a call</button>
        </div>
      </div>
      <div class="mid-right">
        <!-- <img src="../assets/nature_002.jpg" alt="Nature 001"> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
</script>

<script>
// @ is an alias to /src


export default {
  components: {
		Icon,
	},

}
</script>

<style scoped>
.home {
  font-family: 'Lato', sans-serif;

}

.top {
  font-size: 1.1rem;
}

.middle {
  
  background-color: rgba(83, 121, 109, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: start;
  color: floralwhite;
  height: 100vh;
}

.mid-left {
  width: 50%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 0 0 40px;
  height: 100%;
}

.mid-left h3 {
  font-weight: 200;
}

.bullet-points {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60%;
}

.bp-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bp-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bp-text {
  width: 80%;
  font-size: 1.25rem;
}

.btnSchedule {
  width: 40%;
}

.mid-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/nature_002.jpg);
  background-size: cover;
  opacity: 0.5;
}

.mid-right img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/** RESPONSIVE DESIGN STYLES */

@media (max-width: 992px) {
  .middle {
    flex-direction: column;
    height: auto;

  }
  
  .mid-left {
    width: 100%;
    padding: 20px 20px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 0 0 0;
    height: 100%;
  }  
  .mid-left h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .bp-group {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .btnSchedule {
    width: 85%;
    margin: auto;
  }

  .mid-right {
    display: none;
  }
}

</style>
