<template>
  <v-app>
    <nav id="navbar" :class="{ 'sticky-on-scroll': showNavbar}" >
      <div class="logo-holder">
        <a href="/"><img src="../src/assets/logo_full.png" alt="Shimna Therapy Logo"></a>
      </div>
      
      <ul class="nav-links">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/services">Services</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li><router-link to="/faq">FAQ</router-link></li>
      </ul>

      <div class="call-to-actions">
        <button onclick="Calendly.initPopupWidget({url: 'https://calendly.com/shimnatherapy?background_color=FFFAF0&text_color=333&primary_color=56b7ad'});return false;" class="btnScheduleNav"><Icon icon="material-symbols:calendar-month-rounded" color="#349b91" width="32" height="32" /> <br> Schedule a call</button>

        <a href="tel:07741852511" class="btnCall"><Icon icon="material-symbols:phone-android-rounded" color="#349b91" width="32" height="32" /> <br> 077 418 52511</a>
      </div>
      
      <div class="hamburger" @click="toggleDropdownMenu()">
        <Icon icon="icon-park-outline:hamburger-button" color="#349b91" width="32" height="32" />
      </div>

    </nav>

    <div class="dropdown-menu">
        <li><router-link to="/" @click="toggleDropdownMenu()">Home</router-link></li>
        <li><router-link to="/about" @click="toggleDropdownMenu()">About</router-link></li>
        <li><router-link to="/services" @click="toggleDropdownMenu()">Services</router-link></li>
        <li><router-link to="/contact" @click="toggleDropdownMenu()">Contact</router-link></li>
        <li><router-link to="/faq" @click="toggleDropdownMenu()">FAQ</router-link></li>
    </div>

    <div class="splash">
      <div class="splash-title">
        <!-- <h1>Shimna Therapy</h1> -->
        <h3>Challenge, Choice, Change</h3>
        <button class="btnSchedule" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/shimnatherapy?background_color=FFFAF0&text_color=333&primary_color=56b7ad'});return false;">Schedule a call</button>
      </div>
    </div>
    <router-view/>
    <footer>
      <div class="footer-content">
        <div class="footer-contact">
          <h3 class="footer-headers">Challenge, Choice, Change</h3>
            <p>Shimna Therapy provides an experienced, professional service both online and face-to-face to support you with your individual needs through integrity, creativity and inspiration.</p>
        </div>
        <div class="footer-nav-links">
          <h3 class="footer-headers">Menu</h3>
          <ul class="quick-links">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/services">Services</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
            <li><router-link to="/faq">FAQ</router-link></li>
          </ul>
        </div>
        <div class="footer-socials">
          <h3 class="footer-headers">Connect</h3>
          <ul class="social-links">
            <li><a class="instagram-icon" href="https://www.instagram.com/shimna.therapy/"><Icon icon="ph:instagram-logo-fill" width="20" height="20" /> <p> Instagram</p></a></li>
            <li><a class="twitter-icon" href="https://twitter.com/ShimnaTherapy"><Icon icon="mdi:twitter" width="20" height="20" /> <p> Twitter</p></a></li>
            <li><a class="mail-icon" href="mailto:info@shimnatherapy.com"><Icon icon="mingcute:mail-send-fill" width="20" height="20" /> <p> info@shimnatherapy.com</p></a></li>
            <li><a class="phone-icon" href="tel:077 418 52511"><Icon icon="material-symbols:phone-android-rounded" width="20" height="20" /> <p> 077 418 52511</p></a></li>
          </ul>
        </div>
      </div>
      <p class="copyright">© Shimna Therapy 2023</p>
    </footer>
  </v-app>
</template>

<script setup>
import { Icon } from '@iconify/vue';
</script>

<script>
export default {
  data() {
    return {
      scrolledDown: false,
      mouseNav: false,
      email: "",
      message: "",
      valid: false,

    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    // window.addEventListener('mouseover', this.handleMouse);

  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    // window.addEventListener('mouseover', this.handleMouse);
  },
  methods: {
    handleScroll() {
      this.scrolledDown = window.scrollY > 0;
    },
    // handleMouse(event) {
    //   this.mouseNav = event.clientY < 160;
    // },
    toggleDropdownMenu() {
      // const hamburgerIcon = document.querySelector('.hamburger Icon');
      const dropdownMenu = document.querySelector('.dropdown-menu')

      dropdownMenu.classList.toggle('open');

    }
  },
  computed: {
    showNavbar() {
      return this.scrolledDown;
    },
    // findNavbar() {
    //   return this.mouseNav;
    // }
  },
  watch: {
    
  }
};
</script>


<style>
/** GENERAL STYLES */
#app {
  font-family: 'Asap', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0 0 0 0;
  /* background-color: rgb(224, 224, 224); */
  background-color: rgb(255, 250, 243);

}

.top {
  padding: 30px 60px;
}

/** NAV STYLES */

nav {
  position: absolute;
  /* transform: translateY(-100%); */
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.45s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 250, 243);
  position: fixed;

}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 70px;
  width: 33%;
}

#navbar ul li {
  margin: 0 10px;
}

#navbar ul li a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
}

#navbar ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: darkslategrey;
    transition: width 0.3s;
}

#navbar ul li a:hover::after {
    width: 100%;
    transition: width 0.4s;
}

@media (min-width: 992px) {
  .sticky-on-scroll {
    position: fixed;
    transform: translateY(0%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* background-color: rgb(224, 224, 224); */
    background-color: rgb(255, 250, 243);
    z-index: 1000;
  }

  .sticky-on-mouseover {
    position: fixed;
    transform: translateY(0%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* background-color: rgb(224, 224, 224); */
    background-color: rgb(255, 250, 243);
    z-index: 1000;
  }
}

.logo-holder {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.logo-holder a {
  width: 80%;
}

.logo-holder img {
  width: 55%;
  height: auto;
  margin-left: 20px;
}

.call-to-actions {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btnScheduleNav {
  width: 40%;
  text-decoration: none;
  color: rgb(15, 15, 15);
  background-color: rgb(255, 250, 243);
  padding: 15px 15px;

  text-align: center;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
}

.btnScheduleNav:hover {
  background-color: rgb(218, 218, 218);
  transition: 0.4s ease-in-out;
}

.btnCall {
  width: 40%;
  text-decoration: none;
  color: rgb(15, 15, 15);
  background-color: rgb(255, 250, 243);
  padding: 15px 15px;

  text-align: center;
  align-items: center;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
}

.btnCall:hover {
  background-color: rgb(218, 218, 218);
  transition: 0.4s ease-in-out;
}

.hamburger {
  display: none;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 1rem;
  top: 65px;
  width: 300px;
  height: 0;
  background-color: rgba(255, 255, 255, 0.767);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  overflow: hidden;
  transition: 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown-menu.open {
  height: 240px;
  transition: 0.6s ease-in-out;
  z-index: 100;
}

.dropdown-menu li {
  list-style: none;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu li:hover {
  background-color: rgba(255, 255, 255, 0.85);
}

.dropdown-menu li a {
  text-decoration: none;
  color: #349b91;
  width: 100%;
  height: 100%;
}

/** RESPONSIVE NAVIGATION DESIGN */

@media (max-width: 992px) {
  nav {
    position: fixed;
    transform: translateY(0%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* background-color: rgb(224, 224, 224); */
    background-color: rgb(255, 250, 243);
    z-index: 1000;
    padding: 10px 15px;
    height: 60px;
  }

  .nav-links {
    display: none;
  }

  .call-to-actions {
    display: none;
  }

  .logo-holder {
    width: auto;
    height: auto;
  }

  .logo-holder img {
    margin-left: 0;
    height: 36px;
    width: auto;
  }

  .hamburger {
    display: block;
    position: fixed;
    right: 1.5rem;
    height: 36px;
    width: 36px;
    z-index: 2000;
    align-items: center;
    text-align: center;
  }

  .hamburger icon {
    width: 100%;
    height: 100%;
  }

  .dropdown-menu {
    display: block;
    position: fixed;
    transition: 0.8s ease-in-out;
  }

  @media (max-width: 430px) {
    
  }
  
}


/** COMPONENT STYLES */

.splash {
  background-image:url('/src/assets/IMG_2848\(1\).jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  min-height: 435px;
  display: flex;
  align-items: center;
}

.splash-title {
  height: 35vh;
  width: 80%;
  margin: auto;
  text-align: start;
  color: floralwhite;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.splash h1 {
  height: 100%;
  width: 100%;
  font-size: 3em;
}

.btnSchedule {
  text-decoration: none;
  color: floralwhite;
  background-color:#56b7ad;
  padding: 15px 15px;
  width: 24%;
  border-radius: 5px;
  text-align: center;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
}

.btnSchedule:hover {
  background-color:#349b91;
  transition: 0.7s;
}

/** SPLASH RESPONIVE DESIGN */

@media (max-width: 992px) {

  .splash {
  background-position: 40% 50%;
  }
  
  .splash-title h3{
    margin-bottom: 10px;
  }

  .btnSchedule {
    width: 65%;
  }
}


/** FOOTER STYLES */

footer {
  /* background-color: rgb(255, 250, 243); */
  background-color: darkslategrey;
  min-height: 35vh;
  color: floralwhite;
}

.footer-content {
  display: flex;
  flex-direction: row;

  padding: 30px 60px;
}

.footer-contact {
  width: 33%;
  height: 100%;
  text-align: start;
  margin-left: 0;
  margin-right: auto;
}


/* .v-field--error:not(.v-field--disabled) .v-label.v-field-label {
  color: floralwhite !important;
}

.v-progress-linear .bg-error {
  color: floralwhite;
}

.v-messages__message {
  color: rgba(255, 153, 0, 0.925) !important;
} */

.footer-form button {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  color: floralwhite;
  background-color:#56b7ad;
  font-size: 1rem;
  float: right;
}

.footer-form button:hover {
  background-color:#349b91;
  transition: 0.7s;
}

.footer-nav-links {
  width: 25%;
  text-align: start;
}

.footer-headers {
  margin-top: 0;
  margin-bottom: 15px;
}

.quick-links {
  list-style: none;
  text-align: start;
  padding: 0;
  margin: 0;
}

.quick-links li a {
  text-decoration: none;
  color: rgb(170, 170, 170);
}

.quick-links li a:hover {
  color: #3AD7CD;

}

.footer-socials {
  width: 25%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.social-links {
  list-style: none;
  
}

.footer-socials a {
  color: rgb(170, 170, 170);
  transition: 0.2s;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer-socials a p {
  margin-left: 10px;
}

.footer-socials a:hover {
  color: #3AD7CD;
}

/* a.instagram-icon:hover {
  background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} 
*/

.copyright {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

/** FOOTER RESPONSIVE DESIGN */

@media (max-width: 992px) {
  .footer-content {
    flex-direction: column;
    padding: 25px 35px;
    margin-bottom: 20px;
  }

  .footer-contact {
    width: 100%;
    height: 100%;
    text-align: start;
    margin-left: 0;
    margin-right: auto;
  }

  .footer-nav-links {
    width: 100%;
  }

  .quick-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    align-items: center;
    text-align: center;
  }

  .quick-links li {
    flex-basis: 15%;
    width: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: end;
  } 

  .quick-links li a {
    width: 100%;
  }

  .footer-socials {
    width: 100%;
  }

  .social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }

  .social-links li {
    margin: 5px 5px;
  }

}

</style>
